// Formatear fecha a formato dd/mm/yyyy
export const formatDate = (selectedDia: Date) => {
  const selected = new Date(selectedDia)
  const day = selected.getDate()
  const month = selected.getMonth() + 1
  const year = selected.getFullYear()
  return `${day}/${month}/${year}`
}

// Formatear fecha con guiones formato dd-mm-yyyy
export const formatDateGuion = (selectedDia: Date) => {
  if(selectedDia){
    const selected = new Date(selectedDia)
    const day = selected.getDate().toString().padStart(2, '0')
    const month = (selected.getMonth() + 1).toString().padStart(2, '0') // devuelve el mes con un cero adelante si es menor a 10
    const year = selected.getFullYear()
    return `${day}-${month}-${year}`
  }else{
    return ''
  }
}

// Formatear fecha con guiones formato yyyy-mm-dd
export const formatDateApi = (selectedDia: Date) => {
  if(selectedDia){
    const selected = new Date(selectedDia)
    const day = selected.getDate().toString().padStart(2, '0')
    const month = (selected.getMonth() + 1).toString().padStart(2, '0') // devuelve el mes con un cero adelante si es menor a 10
    const year = selected.getFullYear()
    return `${year}-${month}-${day}`
  }else{
    return ''
  }
}

// Formatear fecha con guiones formato yyyy-mm-dd
export const convertDateFormat = (input: any): any => {
  const [day, month, year] = input?.split("-") ?? formatDateGuion(new Date())
  return `${year}-${month}-${day}`;
}

// Formatear fecha para el search
export const formatFakeDate = (selectedDia: Date) => {
  const selected = new Date(selectedDia)
  const day = selected.getDate()
  const month = selected.getMonth()
  const monthChar = new Date(0, month).toLocaleString('default', { month: 'short' })?.substring(0, 3)
  const capitalizedMonth = monthChar?.charAt(0).toUpperCase() + monthChar?.slice(1)
  return `${day} ${capitalizedMonth}.`
}

// Obtener si es de 12 o 24 horas
export const changeHourFormat = (idioma: string) => {
  const format = new Intl.DateTimeFormat(idioma, { hour: 'numeric' }).resolvedOptions().hourCycle;
  if (format === 'h11' || format === 'h12') {
    return true
  }
  return false
}

// Formatear string hora a formato 12 horas
export const format12Hour = (time: string) => {
  const [hours, minutes] = time.split(':');
  return `${((+hours % 12) || 12)}:${minutes} ${+hours < 12 ? 'AM' : 'PM'}`
}

// Format de fecha guion a date: dd-mm-yyyy -> Date
export const getCookieDate = (fechaGuion: string) => {
  const dateString: any = fechaGuion
  const dateParts = dateString?.split("-")
  const mifecha = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
  return mifecha
}